import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings';
import {ISettingsParams} from '../../domain/utils/settings.utils';

export type ISideCartPublicData = Partial<{[k in keyof ISettingsParams<typeof settingsParams>]: string | {}}>;

export const settingsParams = {
  CART_TITLE: {
    key: 'CART_TITLE',
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('sideCart.title.label'),
    inheritFromAppScope: true,
  },
  CART_DISCLAIMER_TEXT: {
    key: 'CART_DISCLAIMER_TEXT',
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('sideCart.settingsPanel.textTab.disclaimer.placeholder'),
    inheritFromAppScope: true,
  },
  CART_EMPTY_CART: {
    key: 'CART_EMPTY_CART',
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('sideCart.cartEmpty.emptyState'),
  },
  SIDE_CART_CART_BUTTON: {
    key: 'SIDE_CART_CART_BUTTON',
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('sideCart.settingsPanel.textTab.goToCart.placeholder'),
    inheritFromAppScope: true,
  },
  SIDE_CART_CHECKOUT_BUTTON: {
    key: 'SIDE_CART_CHECKOUT_BUTTON',
    type: SettingsParamType.String,
    getDefaultValue: ({t}: {t: Function}) => t('sideCart.settingsPanel.textTab.goToCheckout.placeholder'),
    inheritFromAppScope: true,
  },
};
export default createSettingsParams<ISettingsParams<typeof settingsParams>>(settingsParams);
